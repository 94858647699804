import React, { Component } from 'react';
// import Identicon from 'identicon.js';
// import makeBlockie from 'ethereum-blockies-base64';

class Gut3 extends Component {

  render() {
    return (

<div class="container">


  <section class="testimonials text-center bg-light">
      <div class="container">

      <div class="row">

      <div class="col-lg-10 mx-auto">
  <h2><strong>Who this helps.</strong></h2>
  <h3 class="textdesc b-5">Victims have a lot to deal with. Lawyers and Agents have a lot to deal with. Courts, claim mediators and arbitrators have a lot to deal with. Private investigators have a lot to deal with.</h3>
      <p class="lead mb-3">This website relies on smart-contracts that interacts with a system of public nodes and blockchains that provide a safe place to store all the information to successfully manage a claim for relief. More specifically it is a Case and Evidence Management System. &nbsp;
      <a href="https://github.com/worldlaw">See Evidencer</a></p>
      <a class="btn btn-primary btn-xl js-scroll-trigger mb-5" href="#magic">Encrypt and Upload Claim</a>
</div>


      <div class="col-lg-4 col-md-4 ">
      <div class="testimonial-item mx-auto mb-5 mb-lg-0">
      <img class="img-fluid rounded-circle mb-3" src="../img/00002-240w.png" alt="WL-testimonials-1"></img>
        <h5>Manage Client Claims</h5>
            <p class="font-weight-light mb-0">Lawyers and agents benefit becuase ithe WorldLaw system was designed specifically for that. There are no replacements to lawyers and legal agents representing the interests of their clients. These are tool that are nearly free to use and only assist in tthe management of tedious records and chain of custody requirements.</p>
      </div>

      </div>
      <div class="col-lg-4 col-md-4">
      <div class="testimonial-item mx-auto mb-5 mb-lg-0">
      <img class="img-fluid rounded-circle mb-3" src="../img/00015-240w.png" alt="WL-testimonials-2"></img>
        <h5>Manage Evidence</h5>
            <p class="font-weight-light mb-0">Preservation and presentation of evidence to court systems is some of the most costly legal costs because of their hidden nature....</p>
      </div>
      </div>
      <div class="col-lg-4 col-md-4">
      <div class="testimonial-item mx-auto mb-5 mb-lg-0">
      <img class="img-fluid rounded-circle mb-3" src="../img/00014-240w.png" alt="WL-testimonials-3"></img>
        <h5>Use the Best Tools</h5>
            <p class="font-weight-light mb-0">Public State Legal Systems</p>
      </div>
      </div>


      <div class="embed-responsive embed-responsive-1by1">
                <iframe class="embed-responsive-item" title="30secwalkthrough" src="../30sec-walkthrough-circled-web3worldlaw.mp4"></iframe>
              </div>


      </div>
      </div>
  </section>


</div>


);
}
}

export default Gut3;
