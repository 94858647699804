import React, { Component } from 'react';
import Identicon from 'identicon.js';
import makeBlockie from 'ethereum-blockies-base64';

class Main extends Component {

  render() {
    return (
<div className="container-fluid mt-5">
<div className="row">
<main role="main" className="col-lg-12 ml-auto mr-auto" style={{ maxWidth: '800px' }}>

<div id="magic"></div>


<hr></hr>

<div className="content mr-auto ml-auto">

<p>&nbsp;</p>
<p class="bigtextblack font-weight-bold">Add an encrypted claim here</p>
<h2 class="mb-2 text-primary"><strong>100-Finney Claims &nbsp;<i class="fab fa-ethereum"></i></strong></h2>
<h5 class="mb-2 text-black">Do not add unencrypted claims unless you want them to be publicly viewed.</h5>



<form onSubmit={(event) => {
event.preventDefault()
const content = this.postContent.value
this.props.createPost(content)
}}>



<div className="form-group mr-sm-2">




<input
id="postContent2"
type="text"
ref={(input) => { this.postContent = input }}

className="form-control"
placeholder="Post your encrypted claim here. It is most l;ikely a string of encrypted text."
required />
</div>



<button type="submit" className="btn btn-primary btn-block">Post Claim </button>
</form>



<hr></hr>


<div className="justify-content-center mt-2 text-danger"><medium>Instant encryption tools:</medium>
<div className="justify-content-center text-info"><small><a href="https://codebeautify.org/encrypt-decrypt" target="_blank" rel="noopener noreferrer" ><span role="img" aria-label="lock">&#128272;</span> AES-Rijndael CBC (cipher block chaining) encryption:</a></small>
<a href="https://tweetnacl.js.org/#/secretbox" target="_blank" rel="noopener noreferrer" ><br></br> <small><span role="img" aria-label="lock">&#128272;</span>  Secret-key authenticated encryption, Public-key authenticated encryption, Hashing, and Public-key signatures </small></a>
<a href="https://www.devglan.com/online-tools/aes-encryption-decryption" target="_blank" rel="noopener noreferrer" ><br></br> <small><span role="img" aria-label="lock">&#128272;</span>  AES Encryption and Decryption Online Tool </small></a>

</div>
</div>


<p>&nbsp;</p>




{ this.props.posts.map((post, key) => {
return(


  
<div className="card mb-4" key={key} >


<div className="card-header">
<img
className='ml-1 rounded-5'
width='24'
height='24'
src={`data:image/png;base64,${new Identicon(post.author, 24).toString()}`}alt="AcctIconImage"/>

<img
className='ml-1 rounded-5'
width='24'
height='24'
src={makeBlockie(post.author, 24).toString()} alt="AcctIconImage"/>


<hr></hr>


<p class="textdesc"><small><strong>Claimant: </strong><span id="account"><emblue>{post.author}</emblue></span></small></p>



<hr></hr>



<small className="text-black">The data below is an encrypted claim. If you want to support the resolution of the claim, select your support and execute a transaction on the public blockchain. <span id="networkId">{post.networkId}</span></small>


</div>


<ul id="postList" className="list-group list-group-flush">



<li className="list-group-item">
<p class="textdesc"><small><strong>Claim: </strong><emblue>{post.content}</emblue></small></p>
</li>


<li key={key} className="list-group-item py-2">


<small className="float-left mt-1 text-success">
Total Staked to Support Claim: {window.web3.utils.fromWei(post.txAmount.toString(), 'Finney')} Finney
</small>






<button
className="btn btn-secondary btn-sm float-right pt-0"
name={post.id}
onClick={(event) => {
let txAmount = window.web3.utils.toWei('100', 'Finney')
console.log(event.target.name, txAmount)
this.props.txAmtPost(event.target.name, txAmount)
}}
>


Support this Claim for: 100-Finney (1/10 Ether)

</button>



</li>
</ul>
</div>



)
})}




</div>
<br></br>
<hr></hr>



</main>
</div>
</div>
);
}
}

export default Main;
