import React, { Component } from 'react';
import Identicon from 'identicon.js';
import makeBlockie from 'ethereum-blockies-base64';

class Gut5 extends Component {

  render() {
    return (

<div class="container">

  <section class="page-section" id="services1">
      <div class="container">
      <div class="row">
      <div class="col-lg-12 text-center">
        <h2 class="section-heading">General Information</h2>
        <h3 class="section-subheading text-muted">open source code - public use of nodes - ethereum blockchain</h3>
      </div>
      </div>

      <div class="row text-center">
      <div class="col-md-4">
    <a href="page-top">
          <span class="fa-stack fa-3x">
            <i class="fas fa-circle fa-stack-2x text-primary "></i>
            <i class="fab fa-twitter-square fa-stack-1x fa-inverse "></i>
          </span></a>
        <h4 class="service-heading">Twitter</h4>
            <p class="text-muted">Social media breaking news account</p>
      </div>

      <div class="col-md-4">
      <a href="page-top">
          <span class="fa-stack fa-3x">
            <i class="fas fa-circle fa-stack-2x text-primary "></i>
            <i class="fab fa-github fa-stack-1x fa-inverse "></i>
          </span></a>
        <h4 class="service-heading">Github source code</h4>
            <p class="text-muted">Github is where the public code can be reviewed</p>
      </div>

      <div class="col-md-4">
      <a href="page-top">
          <span class="fa-stack fa-3x">
            <i class="fas fa-circle fa-stack-2x text-primary "></i>
            <i class="fab fa-bitcoin fa-stack-1x fa-inverse "></i>
          </span></a>
        <h4 class="service-heading">Use Bitcoin to buy Ether</h4>
            <p class="text-muted">Some transactions required a small fee that is paid in Ether, the currency of the Ethereum blockchain. Using Bitcoin to purchase Ether is one way to get started and use the WorldLaw protocols.</p>
      </div>
      </div>
      </div>
  </section>

    <hr></hr>

  <section class="page-section" id="services2">
      <div class="container">
      <div class="row">
      <div class="col-lg-12 text-center">
        <h2 class="section-heading">The Operations</h2>
        <h3 class="section-subheading text-muted">Micro-transaction fees - encrypted file storage and retrieval - nodejs engine</h3>
      </div>
      </div>

      <div class="row text-center">
      <div class="col-md-4">
      <a href="page-top">
          <span class="fa-stack fa-3x">
            <i class="fas fa-circle fa-stack-2x text-primary "></i>
            <i class="fab fa-ethereum fa-stack-1x fa-inverse "></i>
          </span></a>
        <h4 class="service-heading">Public Ethereum persistence</h4>
            <p class="text-muted">Encrypt and Upload Content</p>
      </div>

      <div class="col-md-4">
      <a href="page-top">
          <span class="fa-stack fa-3x">
            <i class="fas fa-circle fa-stack-2x text-primary "></i>
            <i class="fas fa-gas-pump fa-stack-1x fa-inverse "></i>
          </span></a>
        <h4 class="service-heading">Ethereum transaction fuel</h4>
            <p class="text-muted">Small amounts of Ether are required for all blockchain transactions</p>
      </div>

      <div class="col-md-4">
      <a href="page-top">
          <span class="fa-stack fa-3x">
            <i class="fas fa-circle fa-stack-2x text-primary "></i>
            <i class="fas fa-dice-d6 fa-stack-1x fa-inverse "></i>
          </span></a>
        <h4 class="service-heading">Swarm and IPFS</h4>
            <p class="text-muted">Content storage systems</p>
      </div>
      </div>
      </div>
  </section>

    <hr></hr>

  <section class="page-section" id="services3">
      <div class="container">
      <div class="row">
      <div class="col-lg-12 text-center">
        <h2 class="section-heading">The Data-Flow</h2>
        <h3 class="section-subheading text-muted">Client-side AES256 encryption - anonymous file upload - courtroom ready 24/7/365</h3>
      </div>
      </div>

      <div class="row text-center">
      <div class="col-md-4">
      <a href="page-top">
          <span class="fa-stack fa-3x">
            <i class="fas fa-circle fa-stack-2x text-primary "></i>
            <i class="fas fa-upload fa-stack-1x fa-inverse "></i>
          </span></a>
        <h4 class="service-heading">Encrypted Files Storage</h4>
            <p class="text-muted">Encrypted content and form-data is copied to nodes and Ethereum blockchain</p>
      </div>

      <div class="col-md-4">
      <a href="page-top">
          <span class="fa-stack fa-3x">
            <i class="fas fa-circle fa-stack-2x text-primary "></i>
            <i class="fas fa-file-download fa-stack-1x fa-inverse "></i>
          </span></a>
        <h4 class="service-heading">Download and Decode</h4>
            <p class="text-muted">Onece ncrypted files are safely stored on content nodes and the Ethereum blockchain they can be existenc-verified and decoded by trusted parties who have a decode key. Files can be re-encrypted for down-the-line viewing without compromising the original decode keys by proxy-re-encrytion schemas.</p>
      </div>

      <div class="col-md-4">
      <a href="page-top">
          <span class="fa-stack fa-3x">
            <i class="fas fa-circle fa-stack-2x text-primary "></i>
            <i class="fas fa-user-tie fa-stack-1x fa-inverse "></i>
          </span></a>
        <h4 class="service-heading">Lawyers and Agents</h4>
            <p class="text-muted">The key beneficiaries of the WorldLaw protocols are servicing professionals. Lawyers, agents, paralegals, and private investigators benefit through lower chain-of-custody and evidence managment costs. These overhead costs are many times over-looked by victims as well as the service provider offering them. The WorldLaw protocols cost fractions to what the real-world actual costs are.</p>
      </div>
      </div>
      </div>
  </section>

    <hr></hr>

  <section class="page-section" id="services4">
      <div class="container">
      <div class="row">
      <div class="col-lg-12 text-center">
        <h2 class="section-heading">System Tools</h2>
        <h3 class="section-subheading text-muted">user reqd - bitcoin to fund wallet - facebook support</h3>
      </div>
      </div>

      <div class="row text-center">
      <div class="col-md-4">
      <a href="page-top">
          <span class="fa-stack fa-3x">
            <i class="fas fa-circle fa-stack-2x text-primary "></i>
            <i class="fas fa-shield-alt fa-stack-1x fa-inverse "></i>
          </span></a>
        <h4 class="service-heading">Chain-of-Custody</h4>
            <p class="text-muted">All admissible evidence must pass through a series of test to satifies the Federal Rules of Evidence. The WorldLaw protocols was developed to pare-down the typical costs of raw evidence making its way into a proper court system and be admitted as evidence. This is done through the use of customized protocols that are fixed in place on the pblic Ethereum blockchain. They are easy to understand yet powerful in delivering what the courts need most: efficiency, reliability, and authenticity.</p>
      </div>

      <div class="col-md-4">
      <a href="page-top">
          <span class="fa-stack fa-3x">
            <i class="fas fa-circle fa-stack-2x text-primary "></i>
            <i class="fas fa-landmark fa-stack-1x fa-inverse "></i>
          </span></a>
        <h4 class="service-heading">Use in existing court systems</h4>
            <p class="text-muted">Satisfies U.S. Federal Rules of Evidence</p>
      </div>

      <div class="col-md-4">
      <a href="page-top">
          <span class="fa-stack fa-3x">
            <i class="fas fa-circle fa-stack-2x text-primary "></i>
            <i class="fas fa-sliders-h fa-stack-1x fa-inverse "></i>
          </span></a>
        <h4 class="service-heading">Administrative Tools</h4>
            <p class="text-muted">Each WorldLaw protocol uses a common schema of form-fields that are easily verifiable by reviewing the underlying smart contract code. The cose is written to be user friendly enough for a non-computer scientist to understand. It is a public distributed database specifically designed for claim, evidence and cleint managment of court cases.</p>
      </div>
      </div>
      </div>
  </section>

  
<div class="d-flex justify-content-center mt-5">

{ this.props.account
              ? <img
                className='ml-1 rounded-5'
                width='24'
                height='24'
                src={makeBlockie(this.props.account, 24).toString()} alt="AcctIconImage"/>
              : <span></span>
            }

            { this.props.account
              ? <img
                className='ml-1 rounded-5'
                width='24'
                height='24'
                src={`data:image/png;base64,${new Identicon(this.props.account, 24).toString()}`} alt="AcctIconImage"/>
              : <span></span>
            }

<div className="text-black">Current Account: <span id="account">{this.props.account}</span>

</div>
</div>


</div>


);
}
}

export default Gut5;
