import React, { Component } from 'react';
import Identicon from 'identicon.js';
import makeBlockie from 'ethereum-blockies-base64';

class GutBot extends Component {

  render() {
    return (

<div class="container">


<section class="call-to-action text-black text-center mb-0"  id="executetx">
    <div class="row">
    <div class="col-xl-9 mx-auto">
      <h2 class="mb-0 text-black"><a href="https://etherscan.io" target="_blank" rel="noopener noreferrer" >Verify Ethereum transactions  &nbsp;<i class="fab fa-ethereum"></i></a></h2>
    </div>
    </div>



<div class="d-flex justify-content-center mt-5">

{ this.props.account
              ? <img
                className='ml-1 rounded-5 mr-2'
                width='24'
                height='24'
                src={makeBlockie(this.props.account, 24).toString()} alt="AcctIconImage"/>
              : <span></span>
            }

            { this.props.account
              ? <img
                className='ml-1 rounded-5 mr-2'
                width='24'
                height='24'
                src={`data:image/png;base64,${new Identicon(this.props.account, 24).toString()}`} alt="AcctIconImage"/>
              : <span></span>
            }

<p class="smaller text-white"><emwhite>Current Account: </emwhite><emyellow><span id="account">{this.props.account}</span></emyellow></p>

</div>

</section>

</div>


);
}
}

export default GutBot;
