import React, { Component } from 'react';
import Identicon from 'identicon.js';
import makeBlockie from 'ethereum-blockies-base64';

class GutTop extends Component {

  render() {
    return (



<div class="container">


<section class="features-icons bg-light text-center">
<div class="container">
<div class="row mb-5">

<div class="col-lg-10 mx-auto">
  <h2 class="mb-1"><strong>Injury Claims</strong></h2>
  <h3 class="textdesc mb-5">
<em>This is a service to manage evidence for victim claims.</em>
  </h3>
      <a class="btn btn-primary btn-xl js-scroll-trigger mb-5" href="#about">Create an Injury Claim</a>
</div>

<div class="col-lg-4 col-md-4">
<div class="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
<div class="features-icons-icon d-flex">
<i class="fas fa-car-crash m-auto fa-4x fa-fw text-primary"></i>
</div>
<h3>Automobile Accidents</h3>
<p class="lead mb-0">An image of a car accident scene is some of the most compelling evidence for juries to consider. Proper chain of custody and authentication is an important aspect of Evidential rules and procedures.</p>
</div>
</div>
<div class="col-lg-4 col-md-4">
<div class="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
<div class="features-icons-icon d-flex">
<i class="fas fa-user-injured m-auto fa-4x fa-fw text-primary"></i>
</div>
<h3>Personal Injury and Domestic Violence</h3>
<p class="lead mb-0">In personal injury and domestic violence cases, off-site storage is paramount in safeguarding the victim. By addinig irrefutable timestamp testimony to Evidential photos and documents is compelling use-case for publically stored/encrypted electronic evidence.</p>
</div>
</div>
<div class="col-lg-4 col-md-4">
<div class="features-icons-item mx-auto mb-0 mb-lg-3">
<div class="features-icons-icon d-flex">
<i class="fas fa-house-damage m-auto fa-4x fa-fw text-primary"></i>
</div>
<h3>Neglignece and Products Liability Claims</h3>
<p class="lead mb-0">There are many facets of negligence and product liability claims and each element of the cause of action must be met. WorldLaw protocols offer a secure, confidential way to preserve evidence with integrated testimony, and timestamps in a permanenent state only to be decoded upon demand.</p>
</div>
</div>
</div>
</div>
</section>

<hr></hr>

<div class="d-flex justify-content-center mt-5">

{ this.props.account
              ? <img
                className='ml-1 rounded-5'
                width='24'
                height='24'
                src={makeBlockie(this.props.account, 24).toString()} alt="AcctIconImage"/>
              : <span></span>
            }

            { this.props.account
              ? <img
                className='ml-1 rounded-5'
                width='24'
                height='24'
                src={`data:image/png;base64,${new Identicon(this.props.account, 24).toString()}`} alt="AcctIconImage"/>
              : <span></span>
            }

<div className="text-black">Current Account: <span id="account">{this.props.account}</span>

</div>
</div>


</div>


);
}
}

export default GutTop;
