import React, { Component } from 'react';
import Identicon from 'identicon.js';
import makeBlockie from 'ethereum-blockies-base64';

class Gut1 extends Component {

  render() {
    return (

      <div class="container">


<section class="features-icons bg-light text-center">
      <div class="container">
      <div class="row mb-5">
<div class="col-lg-10 mx-auto">
  <h2><strong>Ethereum Public Blockchain</strong></h2>
      <p class="lead">WorldLaw uses encryption, node computing, and the public Ethereum blockchainfor reliability and persistence of sensitive evidence and electronic data.</p>
</div>

      <div class="col-lg-4 col-md-4">
      <div class="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
      <div class="features-icons-icon d-flex">
            <i class="fas fa-lock m-auto fa-3x fa-fw text-primary"></i>
      </div>
        <h3>Local AES256 Encryption</h3>
            <p class="textdesc mb-0">Advanced Encryption Standard (AES) is a symmetric encryption algorithm designed to be efficient and supports a block length of 256 bits.</p>
      </div>
      </div>
      <div class="col-lg-4 col-md-4">
      <div class="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
      <div class="features-icons-icon d-flex">
            <i class="fas fa-link m-auto fa-3x fa-fw text-primary"></i>
      </div>
        <h3>Public Blockchain Ensures Integrity</h3>
            <p class="textdesc mb-0">Ethereum is a public blockchain and Swarm and IPFS are public storage systems using computer nodes to store “chunks” of dtata that are arranged and re-assembled when the entire file needs to be retrieved.</p>
      </div>
      </div>
      <div class="col-lg-4 col-md-4">
      <div class="features-icons-item mx-auto mb-0 mb-lg-3">
      <div class="features-icons-icon d-flex">
            <i class="fas fa-lock-open m-auto fa-3x fa-fw text-primary"></i>
      </div>
        <h3>Decode & View Documents</h3>
            <p class="textdesc mb-0">Once the documents and image files are encrypted and safely stored on the public nodes and blockchain the WorldLaw protocols are used to help managed the files for court adjudication.</p>
      </div>
      </div>
      </div>
      </div>
  </section>

  <hr></hr>

<section class="features-icons bg-light text-center">
<div class="container">
<div class="row mb-5">

<div class="col-lg-10 mx-auto">
  <h2><strong>WorldLaw Protocols</strong></h2>
      <p class="textdesc mb-5">The WorldLaw system of services provide legal resources and efficiency to complement existing court systems y providing lawyers and agents tools to quickly navigate complex rules of evidence and admissibility of testimonials statements.
      <a href="https://github.com/worldlaw"> These protocols are designed and arranged to be easy to use and simple to understand. Legal frameworks are complex and breaking down the complex to manageable subsets is an important step in providing inexpensive justice to injury victims.</a></p>
      <a class="btn btn-primary btn-xl js-scroll-trigger mb-5" href="#magic">Integrate with Existing Court Systems</a>
</div>



<div class="col-lg-4 col-md-4">
<div class="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
<div class="features-icons-icon d-flex">
      <i class="fas fa-balance-scale m-auto fa-2x fa-fw text-primary"></i>
</div>
  <h3>Legal Document Access</h3>
      <p class="textdesc mb-0">WorldLaw provides a protocol to integrate actual case law and jury instructions into the blockchain stored evidence. Most cases will settle out-of court but always are framed agains the backdrop of jury instructions that condense the essential rules of laws into easily undestandable concepts.</p>
</div>
</div>
<div class="col-lg-4 col-md-4">
<div class="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
<div class="features-icons-icon d-flex">
      <i class="fas fa-globe-americas m-auto fa-2x fa-fw text-primary"></i>
</div>
  <h3>Universally Accepted Framework</h3>
      <p class="textdesc mb-0">WorldLaw protocols can be customized for any jurisdiction because the evidence is the star of this show. Element of the rules of law always speak to the voracity, authenticity, and reliability of the evidence and the testimony attached thereto.</p>
</div>
</div>
<div class="col-lg-4 col-md-4">
<div class="features-icons-item mx-auto mb-0 mb-lg-3">
<div class="features-icons-icon d-flex">
      <i class="fas fa-landmark m-auto fa-2x fa-fw text-primary"></i>
</div>
  <h3>In-Court Presentation 24/7/365</h3>
      <p class="lead mb-0">The WorldLaw protocols are designed to be used at all levels of dispute resolution from initial discovery, alternative dispute resolution, to jury vericts. The encrypted evidenctiary files will only be revealed as the court or parties require. This provides a unprecedented layer or privacy and security usually on reserved for the most high-profile or sensitive cases.</p>
</div>
</div>
</div>
</div>
</section>

<hr></hr>


  <section class="features-icons bg-light text-center">
      <div class="container">

      <div class="col-lg-10 mx-auto">
        <h2><strong>Easy System Use</strong></h2>
            <p class="lead mb-5">Pass your documents and images through a local encrpytion schema and copy to the public nodes. &nbsp;
            <a href="https://github.com/worldlaw">Evidencer</a></p>
            <a class="btn btn-primary btn-xl js-scroll-trigger mb-5" href="#magic">Add Testimony and Encrypt</a>
      </div>

      <div class="row mb-5">
          
      <div class="col-lg-4 col-md-4">
      <div class="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
      <div class="features-icons-icon d-flex">
            <i class="far fa-copy m-auto fa-2x fa-fw text-primary"></i>
      </div>
        <h3>Encrypt and Copy</h3>
            <p class="textdesc mb-0">Local File Encryption then sent to chain and nodes.</p>
      </div>
      </div>
      <div class="col-lg-4 col-md-4">
      <div class="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
      <div class="features-icons-icon d-flex">
            <i class="far fa-comment-alt m-auto fa-2x fa-fw text-primary"></i>
      </div>
        <h3>Add Testimony</h3>
            <p class="textdesc mb-0">Add a testimonial statement to the electronic Evidential content file.</p>
      </div>
      </div>
      <div class="col-lg-4 col-md-4">
      <div class="features-icons-item mx-auto mb-0 mb-lg-3">
      <div class="features-icons-icon d-flex">
            <i class="fas fa-upload m-auto fa-2x fa-fw text-primary"></i>
      </div>
        <h3>Upload to Public Nodes</h3>
            <p class="textdesc mb-0">After the content files, testimony, and form-data is locally encrypted, the “data-blob” is sent to a series of public IPFS and Swarm nodes along with a locator file to the public ethereum Blockchain.</p>
      </div>
      </div>
      </div>
      </div>
  </section>

  <hr></hr>


  <section class="features-icons bg-light text-center">
      <div class="container">
      <div class="row mb-5">

      <div class="col-lg-10 mx-auto">
        <h2><strong>Simple Step-by-Step</strong></h2>
            <p class="lead mb-5">Using your phone camera, capture images or use files saved on you computer. Use the WorldLaw protocol located TidBits.eth to locally (on your own device) encrypt the files. One the files are safely stored on the public nodes and blockchain, the encrypted files can be downloaded and decoded for viewing. &nbsp;
            <a href="https://github.com/worldlaw">Tidbits</a></p>
            <a class="btn btn-primary btn-xl js-scroll-trigger mb-5" href="#magic">Use System Now</a>
      </div>


      <div class="col-lg-4 col-md-4">
      <div class="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
      <div class="features-icons-icon d-flex">
            <i class="fas fa-mobile-alt m-auto fa-2x fa-fw text-primary"></i>
      </div>
        <h3>Mobile & Desktop Ready</h3>
            <p class="lead mb-0">Use your phone camera or files saved on you computer</p>
      </div>
      </div>
      <div class="col-lg-4 col-md-4">
      <div class="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
      <div class="features-icons-icon d-flex">
            <i class="fas fa-fingerprint m-auto fa-2x fa-fw text-primary"></i>
      </div>
        <h3>AES256 Encryption</h3>
            <p class="lead mb-0">Pass through local AES256 generated encryption</p>
      </div>
      </div>
      <div class="col-lg-4 col-md-4">
      <div class="features-icons-item mx-auto mb-0 mb-lg-3">
      <div class="features-icons-icon d-flex">
            <i class="fas fa-file-download m-auto fa-2x fa-fw text-primary"></i>
      </div>
        <h3>Download & Decode</h3>
            <p class="lead mb-0">Using the WorldLaw.eth tools, you will be able to locate, parse, and view the encrypted files provided you possess the decode key used originally to encrypt the content files.</p>
      </div>
      </div>
      </div>
      </div>
  </section>

  <hr></hr>

  <section class="content-section bg-light" id="about6">

<div class="container text-center my-auto">
<div class="row">
<div class="col-lg-10 mx-auto">
  <h2><strong>WorldLaw Provides:</strong></h2>
      <p class="lead mb-5">WorldlLaw provides tailored forms that capture electronic evidence like images, videos and documents and add timestamped testimonial statements to each record. THese file are encrypted on the users local device and then sent to public nodes and theEthereum blockchain. These files remain encrypted until a user who possesses the decod key uses WorldLaw Evidencer protocl to retrieve and decode the files.
      <a href="https://github.com/worldlaw"> WorldLaw</a></p>
      <a class="btn btn-primary btn-xl js-scroll-trigger" href="#magic">Catalog and View Testimonial Statements</a>
</div>
</div>
</div>
</section>

<section class="features-icons bg-light text-center">
<div class="container">
<div class="row mb-5">
<div class="col-lg-4 col-md-4">
<div class="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
<div class="features-icons-icon d-flex">
      <i class="fas fa-list-ul m-auto fa-2x fa-fw text-primary"></i>
</div>
  <h3>Evidence Logs</h3>
      <p class="lead mb-0">Each file uploaded to the content nodes will be added to a log that only the user has access to. Each file was pre-encrypted so even though the file is stored publically, all the infomation is encrypted and un-readable without the decode key.</p>
</div>
</div>
<div class="col-lg-4 col-md-4">
<div class="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
<div class="features-icons-icon d-flex">
      <i class="far fa-file-image m-auto fa-2x fa-fw text-primary"></i>
</div>
  <h3>Decode Image Files and Documents</h3>
      <p class="lead mb-0">Using the Evidencer.eth protocol, the user will be able to locate all the relevant files, testimony and form-data and use the decode key to unencrypt the data and locally view the contents,</p>
</div>
</div>
<div class="col-lg-4 col-md-4">
<div class="features-icons-item mx-auto mb-0 mb-lg-3">
<div class="features-icons-icon d-flex">
      <i class="far fa-comment-alt m-auto fa-2x fa-fw text-primary"></i>
</div>
  <h3>Apply Testimony</h3>
      <p class="lead mb-0">Testimony is applied to each evidence exhibit</p>
</div>
</div>
</div>
</div>
</section>

<hr></hr>

<div class="d-flex justify-content-center mt-5">

{ this.props.account
              ? <img
                className='ml-1 rounded-5'
                width='24'
                height='24'
                src={makeBlockie(this.props.account, 24).toString()} alt="AcctIconImage"/>
              : <span></span>
            }

            { this.props.account
              ? <img
                className='ml-1 rounded-5'
                width='24'
                height='24'
                src={`data:image/png;base64,${new Identicon(this.props.account, 24).toString()}`} alt="AcctIconImage"/>
              : <span></span>
            }

<div className="text-black">Current Account: <span id="account">{this.props.account}</span>

</div>
</div>

</div>


);
}
}

export default Gut1;
